<template>
  <CCard
    color="second-card-bg"
    class="ccard shadow-none"
  >
    <CCardHeader class="ccard-header">
      {{ title }}
    </CCardHeader>
    <CCardBody class="ccard-body">
      <div class="input-file-wrapper">
        <input
          type="file"
          :accept="accept"
          horizontal
          @change="onChangeInputFile"
          ref="fileupload"
        />
        <CButton
          class="btn-sm shadow-none"
          color="outline-primary"
          v-if="!disabledForm"
          @click="deleteFile"
        >
          <CIcon
            name="cil-x"
          />
        </CButton>
      </div>
      <CButton
        class="mt-4 shadow-none"
        color="primary"
        :disabled="disabledForm"
        @click="onClickUploadFile"
      >
        {{ buttonText }}
        <CIcon
          name="cil-cloud-upload"
        />
      </CButton>
    </CCardBody>
  </CCard>
</template>
<script>
export default {
  name: 'InputFile',
  props: {
    title: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    },
    accept: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      disabledForm: true,
      file: null
    }
  },
  methods: {
    onChangeInputFile(evt) {
      const files = evt.target.files
      if (files.length > 0) {
        this.file = files[0]
        this.disabledForm = false
      }
    },
    deleteFile() {
      this.file = null
      this.$refs.fileupload.value = null;
      this.disabledForm = true
    },
    onClickUploadFile() {
      this.$emit('upload-file', this.file)
    }
  }
}
</script>

<style lang="scss" scoped>
.ccard {
  .ccard-header {
    padding: 1.25rem 2rem;
  }
  .ccard-body {
    padding: 1.5rem 2rem;
  }
}

.input-file-wrapper {
  display: flex;
  align-items: center;
}

.btn:disabled {
  cursor: not-allowed;
}
</style>