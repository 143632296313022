var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    { staticClass: "ccard shadow-none", attrs: { color: "second-card-bg" } },
    [
      _c(
        "CCardHeader",
        { staticClass: "ccard-header" },
        [
          _c("span", [_vm._v("Whitelist")]),
          _vm.loading
            ? _c("CSpinner", {
                staticClass: "ml-3",
                attrs: { color: "primary", size: "sm" },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "CCardBody",
        { staticClass: "ccard-body d-flex flex-column" },
        [
          _c("CInput", {
            attrs: { placeholder: _vm.$t("general.inputPlaceholder") },
            scopedSlots: _vm._u([
              {
                key: "prepend",
                fn: function () {
                  return [
                    _c(
                      "CButton",
                      {
                        staticClass: "shadow-none",
                        attrs: { color: "primary" },
                      },
                      [
                        _c("CIcon", { attrs: { name: "cil-search" } }),
                        _vm._v(" " + _vm._s(_vm.$t("general.search")) + " "),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.email,
              callback: function ($$v) {
                _vm.email = $$v
              },
              expression: "email",
            },
          }),
          _c("div", { staticClass: "table-wrap" }, [
            _c("table", { staticClass: "table mb-0" }, [
              _c("thead", [
                _c(
                  "tr",
                  _vm._l(_vm.fields, function (field, i) {
                    return _c("th", { key: i }, [
                      _vm._v(" " + _vm._s(field.label) + " "),
                    ])
                  }),
                  0
                ),
              ]),
              _c(
                "tbody",
                _vm._l(_vm.items, function (item, i) {
                  return _c(
                    "tr",
                    { key: i },
                    _vm._l(_vm.fields, function (field, i) {
                      return _c("td", { key: "field-" + i }, [
                        _vm._v(" " + _vm._s(item[field.key]) + " "),
                      ])
                    }),
                    0
                  )
                }),
                0
              ),
            ]),
          ]),
          _c("CPagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.totalPages > 1,
                expression: "totalPages > 1",
              },
            ],
            staticClass: "mt-2 pagination-custom",
            class: { _disabled: _vm.loading },
            attrs: { pages: _vm.totalPages, activePage: _vm.page },
            on: {
              "update:activePage": function ($event) {
                _vm.page = $event
              },
              "update:active-page": function ($event) {
                _vm.page = $event
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }