var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "whitelist" },
    [
      _c("h1", { staticClass: "title" }, [_vm._v("Whitelist")]),
      _c(
        "CRow",
        { staticClass: "flex-grow-1" },
        [
          _c(
            "CCol",
            { attrs: { col: "12", lg: "4" } },
            [
              _c("input-file", {
                staticClass: "input-file",
                attrs: {
                  title: _vm.$t("lk.whitelist.codeListDownload"),
                  "button-text": `${_vm.$t("lk.whitelist.download")} whitelist`,
                  accept: ".xls, .xlsx, .ods",
                },
                on: { "upload-file": _vm.onUploadFile },
              }),
              _c("input-hint", { staticClass: "input-hint" }),
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { col: "12", xl: "8" } },
            [
              _c("whitelist-table", {
                attrs: {
                  items: _vm.items,
                  fields: _vm.fields,
                  loading: _vm.loading,
                  totalPages: _vm.totalPages,
                },
                on: {
                  "change-email": _vm.changeEmail,
                  "change-page": _vm.changePage,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }