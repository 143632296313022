<template>
  <CCard
    color="second-card-bg"
    class="ccard shadow-none"
  >
    <CCardHeader class="ccard-header d-flex">
      <span>{{ $t('lk.whitelist.inputHint.downloadFileHint') }}</span>
      <CLink :href="`${BUILD_PUBLIC_PATH}/example.xlsx`" class="ml-auto text-primary">{{ $t('lk.whitelist.inputHint.example') }}</CLink>
    </CCardHeader>
    <CCardBody class="ccard-body" v-html="$t('lk.whitelist.inputHint.enterTheRates')" />
  </CCard>
</template>
<script>

import {BUILD_PUBLIC_PATH} from '@/assets/js/constants'

export default {
  name: 'InputHint',
  data(){
    return {
      BUILD_PUBLIC_PATH: BUILD_PUBLIC_PATH,
    }
  },
}
</script>

<style lang="scss" scoped>
.ccard {
  .ccard-header {
    padding: 1.25rem 2rem;
  }
  .ccard-body {
    padding: 1.5rem 2rem;
  }
}
</style>
