<template>
  <div class="whitelist">
    <h1 class="title">Whitelist</h1>

    <CRow class="flex-grow-1">
      <CCol col="12" lg="4">
        <input-file
          class="input-file"
          :title="$t('lk.whitelist.codeListDownload')"
          :button-text="`${$t('lk.whitelist.download')} whitelist`"
          :accept="'.xls, .xlsx, .ods'"
          @upload-file="onUploadFile"
        />
        <input-hint class="input-hint" />
      </CCol>
      <CCol col="12" xl="8">
        <whitelist-table
          :items="items"
          :fields="fields"
          :loading="loading"
          :totalPages="totalPages"
          @change-email="changeEmail"
          @change-page="changePage"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios';
import WhitelistTable from "@/components/whitelist/WhitelistTable";
import InputFile from '@/components/account/InputFile.vue';
import InputHint from "@/components/account/InputHint";
export default {
  name: "Whitelist",
  components: {
    WhitelistTable,
    InputFile,
    InputHint
  },
  data() {
    return {
      loading: false,
      items: [],
      fields: [
        {
          key: 'user',
          label: this.$t('lk.whitelist.table.user')
        },
        {
          key: 'tariff_name',
          label: this.$t('lk.whitelist.table.tariff')
        },
        {
          key: 'tariff_term_days',
          label: this.$t('lk.whitelist.table.term')
        },
        {
          key: 'tt_enable_trends',
          label: this.$t('lk.whitelist.table.trends')
        }
      ],
      email: '',
      page: 1,
      totalPages: null
    }
  },
  watch: {
    email () {
      this.getWhiteList()
    },
    page () {
      this.getWhiteList()
    }
  },
  created() {
    this.getWhiteList()
  },
  methods: {
    onUploadFile(file) {
      let formData = new FormData();
      formData.append('whitelist', file);
      this.loading = true

      axios.post('/api/v1/users/import-white-list',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => {
          if (res.status === 200) {
            this.getWhiteList()
          }
        })
        .catch(e => {
          if (e.response.status === 422) {
            this.getWhiteList()
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    getWhiteList() {
      this.loading = true
      const params = {
        page: this.page
      }

      if (this.email.length > 0) {
        params.email = this.email
      }

      axios.get('/api/v1/users/white-list', { params })
        .then(res => {
          this.items = res?.data?.data ? this.parseWhiteListData(res?.data?.data) : []
          this.page = res?.data?.meta?.current_page
          this.totalPages = res?.data?.meta?.last_page
        })
        .finally(() => {
          this.loading = false
        })
    },
    parseWhiteListData(data) {
      return data.map((item) => {
        return {
          user: item.email,
          tariff_term_days: item.tariff_term_days,
          tariff_name: item.tariff?.name ?? '-',
          tt_enable_trends: item.tt_enable_trends,
        }
      })
    },
    changeEmail(email) {
      if (email === '' || email.length > 2) {
        this.email = email
      }
    },
    changePage(page) {
      this.page = page
    }
  }
}
</script>

<style lang="scss" scoped>
.whitelist {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-down(xs) {
    .title {
      display: none;
    }
  }
  @include media-breakpoint-up(sm) {

    .title {
      font-size: 1.25rem;
      margin-bottom: 1.75rem;
    }

  }
  @include media-breakpoint-down(lg) {
    .input-hint {
      display: none;
    }
    .input-file {
      display: none;
    }
  }
  @include media-breakpoint-up(xl) {

    .title {
      font-size: 2.25rem;
      margin-bottom: 2.25rem;
    }
  }
}
</style>
