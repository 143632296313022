var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    { staticClass: "ccard shadow-none", attrs: { color: "second-card-bg" } },
    [
      _c(
        "CCardHeader",
        { staticClass: "ccard-header d-flex" },
        [
          _c("span", [
            _vm._v(_vm._s(_vm.$t("lk.whitelist.inputHint.downloadFileHint"))),
          ]),
          _c(
            "CLink",
            {
              staticClass: "ml-auto text-primary",
              attrs: { href: `${_vm.BUILD_PUBLIC_PATH}/example.xlsx` },
            },
            [_vm._v(_vm._s(_vm.$t("lk.whitelist.inputHint.example")))]
          ),
        ],
        1
      ),
      _c("CCardBody", {
        staticClass: "ccard-body",
        domProps: {
          innerHTML: _vm._s(_vm.$t("lk.whitelist.inputHint.enterTheRates")),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }