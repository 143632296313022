var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    { staticClass: "ccard shadow-none", attrs: { color: "second-card-bg" } },
    [
      _c("CCardHeader", { staticClass: "ccard-header" }, [
        _vm._v(" " + _vm._s(_vm.title) + " "),
      ]),
      _c(
        "CCardBody",
        { staticClass: "ccard-body" },
        [
          _c(
            "div",
            { staticClass: "input-file-wrapper" },
            [
              _c("input", {
                ref: "fileupload",
                attrs: { type: "file", accept: _vm.accept, horizontal: "" },
                on: { change: _vm.onChangeInputFile },
              }),
              !_vm.disabledForm
                ? _c(
                    "CButton",
                    {
                      staticClass: "btn-sm shadow-none",
                      attrs: { color: "outline-primary" },
                      on: { click: _vm.deleteFile },
                    },
                    [_c("CIcon", { attrs: { name: "cil-x" } })],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "CButton",
            {
              staticClass: "mt-4 shadow-none",
              attrs: { color: "primary", disabled: _vm.disabledForm },
              on: { click: _vm.onClickUploadFile },
            },
            [
              _vm._v(" " + _vm._s(_vm.buttonText) + " "),
              _c("CIcon", { attrs: { name: "cil-cloud-upload" } }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }