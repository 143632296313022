<template>
  <CCard
    color="second-card-bg"
    class="ccard shadow-none"
  >
    <CCardHeader class="ccard-header">
      <span>Whitelist</span>
      <CSpinner
        class="ml-3"
        v-if="loading"
        color="primary"
        size="sm"
      />
    </CCardHeader>
    <CCardBody class="ccard-body d-flex flex-column">
      <CInput
        v-model="email"
        :placeholder="$t('general.inputPlaceholder')"
      >
        <template #prepend>
          <CButton class="shadow-none" color="primary">
            <CIcon name="cil-search"/>
            {{ $t('general.search') }}
          </CButton>
        </template>
      </CInput>
      <div class="table-wrap">
        <table class="table mb-0">
          <thead>
            <tr>
              <th v-for="(field, i) in fields" :key="i">
                {{ field.label }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item, i) in items" :key="i">
              <td
                v-for="(field, i) in fields"
                :key="'field-'+i"
              >
                {{item[field.key]}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <CPagination
        class="mt-2 pagination-custom"
        :class="{_disabled: loading}"
        v-show="totalPages > 1"
        :pages="totalPages"
        :activePage.sync="page"
      />
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  name: "WhitelistTable",
  props: {
    items: {
      type: Array,
      default: () => ([])
    },
    fields: {
      type: Array,
      default: () => ([])
    },
    loading: {
      type: Boolean,
      default: false
    },
    totalPages: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      email: '',
      page: 1
    }
  },
  watch: {
    email () {
      this.$emit('change-email', this.email)
    },
    page (val, oldVal) {
      if (val && val !== oldVal) {
        this.$emit('change-page', this.page)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ccard {
  margin-bottom: 0;

  @include media-breakpoint-down(xs) {
    .ccard-body {
      padding: 1rem 1.75rem;
    }
  }
  @include media-breakpoint-up(sm) {
    .ccard-body {
      padding: 1.25rem 1.75rem;
    }
  }
  @include media-breakpoint-down(lg) {
    margin: 0 -2rem;

    .ccard-header {
      display: none;
    }
  }
  @include media-breakpoint-up(xl) {
    .ccard-header {
      padding: 1.25rem 2rem;
    }
  }
}

.table-wrap {
  overflow: auto;
  width: 100%;
  flex-grow: 1;
}
.table {
  position: relative;
  thead tr th {
    background: var(--second-card-bg);
    position: sticky;
    top: -1px;
    z-index: 1;
  }
}
</style>
